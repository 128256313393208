'use client'


import { useState, useEffect , useRef } from 'react';
import axios from '../../utils/axiosConfig';
import MainLayout from '../../layouts/MainLayout';
import {useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
import './statistiques.css'



const Statistiques = () => {
  const navigate = useNavigate();



  const token = useSelector((state) => state.auth.token);
  const [users, setUsers] = useState(null);
  const [web, setWeb] = useState(null);
  const [mobil, setMobil] = useState(null);
  const [appliweb, setAppweb] = useState(null);
  const fetchAllUsers = async () => {
      try {
          const response = await axios.get('users/number/', {
              headers: {
                  'Authorization': `Bearer ${token.access}`
              }
          });
          setUsers(response.data.user_count); // On stocke les utilisateurs récupérés dans l'état
      } catch (error) {
          console.error("Erreur lors de la récupération des informations des utilisateurs", error);
          alert("Erreur : " + error.message);
      }
  };

  useEffect(() => {
      fetchAllUsers();
  }, [token.access]); // Utiliser le token.access comme dépendance




  const fetchAllWebs = async () => {
    try {
        const response = await axios.get('projects/sites', {
            headers: {
                'Authorization': `Bearer ${token.access}`
            }
        });
        setWeb(response.data.web_projects_count); // On stocke les utilisateurs récupérés dans l'état
    } catch (error) {
        console.error("Erreur lors de la récupération des informations des utilisateurs", error);
        alert("Erreur : " + error.message);
    }
};

useEffect(() => {
    fetchAllWebs();
}, [token.access]); // Utiliser le token.access comme dépendance



const fetchAllWebApps = async () => {
  try {
      const response = await axios.get('projects/sites', {
          headers: {
              'Authorization': `Bearer ${token.access}`
          }
      });
      setAppweb(response.data.web_projects_count); // On stocke les utilisateurs récupérés dans l'état
  } catch (error) {
      console.error("Erreur lors de la récupération des informations des utilisateurs", error);
      alert("Erreur : " + error.message);
  }
};

useEffect(() => {
  fetchAllWebApps();
}, [token.access]); // Utiliser le token.access comme dépendance





const fetchAllMobilApps = async () => {
  try {
      const response = await axios.get('projects/applimobil', {
          headers: {
              'Authorization': `Bearer ${token.access}`
          }
      });
      setMobil(response.data.mobil_projects_count); // On stocke les utilisateurs récupérés dans l'état
  } catch (error) {
      console.error("Erreur lors de la récupération des informations des utilisateurs", error);
      alert("Erreur : " + error.message);
  }
};

useEffect(() => {
  fetchAllMobilApps();
}, [token.access]); // Utiliser le token.access comme dépendance





  return (
    <MainLayout>
      <div className='page1'>

        <div className='contain1'>

          
          <div class='line'>
          
            <div className='item'>
              <div className='header'><center><h4>Users</h4></center></div>
              <div className='body'><div className='item_users'></div></div>
              <div className='number'><center><h1>{users}</h1></center></div>
            </div>

            <div className='item'>
              <div className='header'><center><h4>Revenus</h4></center></div>
              <div className='body'><div className='item_revenus' onClick={()=> navigate("./revenus")}></div></div>
            </div>

            <div className='item'>
              <div className='header'><center><h4>Total Apps</h4></center></div>
              <div className='body'><div className='item_total'></div></div>
              <div className='number'><center><h1>{mobil + web +appliweb}</h1></center></div>
            </div>
          </div>



          <div class='line'>
          
            <div className='item'>
              <div className='header'><center><h4>Sites Web</h4></center></div>
              <div className='body'><div className='item_sites'></div></div>
              <div className='number'><center><h1>{web}</h1></center></div>
            </div>

            <div className='item'>
              <div className='header'><center><h4>Applications Web</h4></center></div>
              <div className='body'><div className='item_appliweb'></div></div>
              <div className='number'><center><h1>{appliweb}</h1></center></div>
            </div>

            <div className='item'>
              <div className='header'><center><h4>Applications Mobiles</h4></center></div>
              <div className='body'><div className='item_applimobil'></div></div>
              <div className='number'><center><h1>{mobil}</h1></center></div>
            </div>
          </div>
            
        </div>

  </div>
    </MainLayout>
    
  )
}
export default Statistiques;




