import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import styles from './projects.module.css';
import MainLayout from '../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import axios, { baseURL } from '../utils/axiosConfig';
import { useSelector } from 'react-redux';
import LoginRequireLayout from '../layouts/LoginRequireLayout';

export default function ProjetsPage() {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [projectName, setProjectName] = useState('');
  const [projectType, setProjectType] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templatesList, setTemplatesList] = useState([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const [projects, setProjects] = useState([]);
  const [isCreateDialogVisible, setCreateDialogVisible] = useState(false);
  const [isRenameDialogVisible, setRenameDialogVisible] = useState(false);
  const [renameProject, setRenameProject] = useState(null);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const op = useRef(null);

  const token = useSelector((state) => state.auth.token); // Récupération du token JWT

  const projectTypes = [
    { label: 'Site web', value: 'web' },
    { label: 'Application web', value: 'web_app' },
    { label: 'Application mobile', value: 'mobile_app' }
  ];


  const steps = [
    { label: 'Nom du projet' },
    { label: 'Choisir un template' }
  ];

  const getProjets = async () => {
    try {
      const response = await axios.get('/projects/', {
        headers: { Authorization: `Bearer ${token.access}` }
      });
      setProjects(response.data);
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération des projets'+error });
    }
  }


  // get the templates list on backend
  // get the templates list on backend 
  const getTemplates = async () => {
    try {
      const response = await axios.get('/templates/', {
        headers: { Authorization: `Bearer ${token.access}` }
      });
      setTemplatesList(response.data);
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération des templates' });
    }
  };
  useEffect( () => {
    getProjets();
    getTemplates()
  }, []);

  const createProject = () => {
    axios.post('/projects/create/', { name: projectName, type: projectType, template: selectedTemplate }, {
      headers: { Authorization: `Bearer ${token.access}` }
    })
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Projet créé avec succès' });
        setProjects([...projects, response.data]);
        setCreateDialogVisible(false);
        setProjectName('');
        navigate(`/editeur/${response.data.id}/`);
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la création du projet' });
      });
  };

  const renameSelectedProject = () => {
    axios.put(`/projects/${renameProject.id}/update/`, { name: renameProject.name }, {
      headers: { Authorization: `Bearer ${token.access}` }
    })
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Projet renommé avec succès' });
        setProjects(projects.map(project => project.id === renameProject.id ? response.data : project));
        setRenameDialogVisible(false);
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du renommage du projet' });
      });
  };

  const deleteProject = () => {
    axios.delete(`/projects/${renameProject.id}/delete/`, {
      headers: { Authorization: `Bearer ${token.access}` }
    })
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Projet supprimé avec succès' });
        setProjects(projects.filter(project => project.id !== renameProject.id));
        setConfirmDeleteVisible(false);
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la suppression du projet' });
      });
  };

  const onNextStep = () => {
    if (activeIndex === 1 && selectedTemplate) {
      createProject();
    } else {
      setActiveIndex((prev) => prev + 1);
    }
  };

  const menuItems =  [
    { label: 'Ouvrir', icon: 'pi pi-fw pi-external-link',command: () => { navigate(`/editeur/${renameProject.id}`)} },
    { label: 'Renommer', icon: 'pi pi-fw pi-pencil', command: () => { setRenameDialogVisible(true)} },
    { label: 'Partager', icon: 'pi pi-fw pi-share-alt' },
    { label: 'Supprimer', icon: 'pi pi-fw pi-trash', command: () => setConfirmDeleteVisible(true) }
  ];

  const handleMenuToggle = (event, item) => {
    menuRef.current.toggle(event);
    if(renameProject){
      setRenameProject(null);
    }else{
      setRenameProject(item);
    }
    setMenuVisible(!menuVisible);
  };

  return (
    <MainLayout>
      <LoginRequireLayout ></LoginRequireLayout>
      <Toast ref={toast} />
      <div className={styles.container}>
        <h1 className={styles.title}>Vos projets:</h1>
        
        <div className={styles.projects}>
          <Card className={styles.addCard} onClick={() => setCreateDialogVisible(true)}>
            <div className={styles.addCardContent}>
              <span className={styles.addIcon}>+</span>
            </div>
          </Card>

          {projects.map((project, index) => (
            <Card key={index} className={styles.projectCard} onContextMenu={handleMenuToggle}>
              <div className={styles.projectContent}>
                <div className={styles.projectAvatar}>
                  <span className={styles.avatarText}>{project.name[0]}</span>
                </div>
                <div className={styles.projectDetails}>
                  <span className={styles.projectTitle}>{project.name}</span>
                  <span className={styles.projectType}>{project.type}</span>
                  <span className={styles.projectDate}>{new Date(project.created_at).toLocaleDateString()}</span>
                </div>
              </div>
              <i className={`pi pi-ellipsis-v ${styles.menuIcon}`} onMouseOver={(e)=>handleMenuToggle(e , project )}></i>
              <Menu model={menuItems} popup ref={menuRef} id="popup_menu" />
            </Card>
          ))}
        </div>

        <Dialog visible={isCreateDialogVisible} style={{ width: '50vw' }} header="Créer un projet" modal onHide={() => setVisible(false)}>
          <Steps model={steps} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />

          {activeIndex === 0 && (
            <div className={styles.stepContent}>
              <h3>Nom du projet</h3>
              <InputText value={projectName} onChange={(e) => setProjectName(e.target.value)} placeholder="Nom du projet" className={styles.input} />
              <h3>Type de projet</h3>
              <Dropdown value={projectType} options={projectTypes} onChange={(e) => {setProjectType(e.value);setTemplatesList((current) => current.filter(template => template.type_template === e.value));}} placeholder="Choisir le type" className={styles.input} />
            </div>
          )}

          {activeIndex === 1 && (
            <div className={styles.stepContent}>
              <h3>Choisir un template</h3>
              <div className={styles.templateGrid}>
                {templatesList.map((template, index) => (
                  <Card key={index} title={template.name} children={<>
                  <img src={baseURL+ template.thumbnail} alt={template.name} className={styles.templateImage} />
                  </>} className={styles.templateCard} onClick={() => setSelectedTemplate(template.id)}
                  footer={<><small>{template.created_by}</small></>} />
                    

                ))}
              </div>
            </div>
          )}

          <div className={styles.stepActions}>
            <Button label="Suivant" onClick={onNextStep} disabled={activeIndex === 1 && !selectedTemplate} />
          </div>
        </Dialog>

        <Dialog header="Renommer le projet" visible={isRenameDialogVisible} style={{ width: '30vw' }} onHide={() => setRenameDialogVisible(false)}>
          <div className="p-fluid" style={{marginBottom:"15px"}} >
            <div className="p-field p-2" style={{gap:"15px"}}>
              <label htmlFor="rename">Nom du projet</label>
              <InputText id="rename" value={renameProject ? renameProject.name : ''} onChange={(e) => setRenameProject({ ...renameProject, name: e.target.value })} />
            </div>
            <Button label="Renommer" onClick={renameSelectedProject} />
          </div>
          </Dialog>

          <ConfirmDialog visible={confirmDeleteVisible} onHide={() => setConfirmDeleteVisible(false)}
          message="Êtes-vous sûr de vouloir supprimer ce projet ?"
          header="Confirmation" icon="pi pi-exclamation-triangle"
          accept={deleteProject} reject={() => setConfirmDeleteVisible(false)} />
      </div>
    </MainLayout>
  );
}


