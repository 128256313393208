import { FaBell, FaUser } from 'react-icons/fa';
import {  useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { Avatar } from 'primereact/avatar';
import { googleLogout } from '@react-oauth/google';
// import axios from '../utils/axiosConfig';
import { clearAuth } from '../utils/redux/slices/authSlice';
import './globals.css';
import styles from './layout.module.css';


export default function RootLayout({ children }) {
    return (
        <html lang="fr">
            <head>
                <meta name="description" content='LegionBuild' />
                <title>LegionBuild</title>
            </head>
            <body>
                <LayoutComponent>
                    {children}
                </LayoutComponent>
            </body>
        </html>
    );
}


const LayoutComponent = ({ children }) => {
    const profile = useSelector(state => state.auth.profile);
    const navigate = useNavigate();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        // Gestionnaire d'événement pour fermer le dropdown lors d'un clic en dehors de celui-ci
        const handleClickOutside = (event) => {
            if (visible && !event.target.closest(`.${styles.userDropdown}`)) {
                setVisible(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [visible]);

    const handleLogout = () => {
        googleLogout();
        dispatch(clearAuth());
        toast.current.show({ severity: 'success', summary: 'Déconnexion', detail: 'Vous avez été déconnecté.', life: 1000 });
        navigate("/");
    };



    const userOptions = profile ? [
        { label: `${profile.first_name} ${profile.last_name}`, className: styles.dropdownHeader },
        { label: `${profile.username}`, className: styles.username },
        profile.is_superuser ? { label: 'administration', command: () => { setVisible(!visible); navigate(`/admin`) } }:
        { label: 'Voir mon profil', command: () => { setVisible(!visible); navigate(`/profile`) } },
        { label: 'Projets', command: () => { setVisible(!visible); navigate(`/projects`) } },
        { label: 'Paramètres', command: () => { setVisible(!visible); navigate(`/my-settings`) } },
        { label: 'Déconnexion', command: () => { setVisible(!visible); handleLogout() } }
    ] :
    [
        { label: 'Anonymous User', className: styles.dropdownHeader },
        { label: 'Connexion', command: () => navigate("/login") }
    ];



    return (
        <div className={styles.container}>
            <Toast ref={toast} />
            <header className={styles.header} style={{paddingTop:"1.5rem"}}>
                <h1 style={{ cursor: "pointer" }} onClick={() => navigate("/")}><img src="/logo.png" alt='LegionBuild'  width={110} height={40}/></h1>
                <div className={styles.profileSection}>
                    <div className={styles.profiles}>
                        <FaBell className={styles.icon} />
                        <div className={styles.userDropdown}>
                            {profile ? (
                                <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                                    <Avatar image={profile.img_url ? `http://${window.location.host.split(":")[0]}:8000${profile.img_url}` : "/img/avatar.jpg"} onClick={() => setVisible(!visible)} style={{ cursor: "pointer" }} shape="circle" />
                                </div>
                            ) : (
                                <>
                                    { (
                                        <FaUser className={styles.icon} onClick={() => setVisible(!visible)} style={{ cursor: "pointer" , marginRight:"20px"}} />
                                    )}
                                    {/* {!isLoggedIn && (
                                        <Button className="p-button-outlined p-mr-2" label="Login" onClick={() => navigate("/login")} />
                                    )} */}
                                    {/* {!isLoggedIn && (
                                        // <GoogleLogin
                                        //     // useOneTap
                                        //     onSuccess={(credentialResponse) => {
                                        //         getUserInfo(credentialResponse.credential);
                                        //     }}
                                        //     onError={() => {
                                        //         toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la connexion Google', life: 3000 });
                                        //     }}
                                        //     className="p-button-outlined"
                                        //     style={{ marginLeft: '10px' }}
                                        // />
                                    )} */}
                                </>
                            )}
                            {visible && (
                                <div className={styles.dropdownMenu}>
                                    {userOptions.map((option, index) => (
                                        <div key={index} className={option.className || styles.dropdownItem} onClick={option.command}>
                                            {option.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <main className={styles.main}>
                {children}
            </main>
            <footer className={styles.footer}>
                <div className={styles.foot}>
                Copyright: LegionWeb. Consulter nos    
                <Link className={styles.a} style={{margin:"2px"}} to="/admin/messages/" target='_blank'>
                    conditions d&apos;utilisation
                </Link> et notre <Link className={styles.a} to="/admin/" target='_blank'>reletation client.</Link>
               </div>
            </footer>
        </div>
    );
};
