
'use client';

import './sites.css'
import MainLayout from '../../layouts/MainLayout';
import axios from '../../utils/axiosConfig';
import { useSelector } from 'react-redux';
import { useState, useEffect , useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Utilisateur = () => {

    const token = useSelector((state) => state.auth.token);
    const [users, setUsers] = useState([]); // État pour stocker les sites

    const fetchAllUsers = async () => {
        try {
            const response = await axios.get('/projects/weblist', {
                headers: {
                    'Authorization': `Bearer ${token.access}`
                }
            });
            setUsers(response.data); // On stocke les sites récupérés dans l'état
        } catch (error) {
            console.error("Erreur lors de la récupération des informations des utilisateurs", error);
            alert("Erreur : " + error.message);
        }
    };

    useEffect(() => {
        fetchAllUsers();
    }, [token.access]); // Utiliser le token.access comme dépendance

    return (
        <MainLayout>
            <div className='page1'>
                <div className='contain1'>
                    <ProductTable users={users} /> {/* Passer les sites en tant que props */}
                </div>
            </div>
        </MainLayout>
    );
}

const ProductTable = ({ users }) => { // Recevoir les sites en tant que prop
    const dt = useRef(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);

    const header = (
        <div className="table-header">
            <InputText
                type="search"
                onInput={(e) => setGlobalFilter(e.target.value)}
                placeholder="Rechercher..."
            />
        </div>
    );

    return (
        <DataTable ref={dt} value={users} selection={selectedUsers} onSelectionChange={(e) => setSelectedUsers(e.value)}
            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affiche {first} à {last} sur {totalRecords} utilisateurs" globalFilter={globalFilter} header={header} >

            <Column selectionMode="multiple" exportable={false}></Column>
            <Column field="name" header="Nom" sortable style={{ minWidth: '12rem' }}></Column>
            <Column field="created_by" header="Proprietaire" sortable style={{ minWidth: '16rem' }}></Column>
            <Column field="created_at" header="date de creation" sortable style={{ minWidth: '16rem' }}></Column>
        </DataTable>
    );
};

export default Utilisateur;