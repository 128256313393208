import React, { useEffect, useState, useRef } from 'react';
import grapesjs from 'grapesjs';
import gjsGoogleMaterialIcons from 'grapesjs-google-material-icons';
import 'grapesjs/dist/css/grapes.min.css';
import gjsPresetWebpage from 'grapesjs-preset-webpage'; 
import gjsForms from 'grapesjs-plugin-forms';

import plugin from 'grapesjs-undraw';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-undraw/dist/grapesjs-undraw.min.css'


import styles from './editor.module.css';
import MainLayout from '../../layouts/MainLayout';
import axios from '../../utils/axiosConfig';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import {Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useLocation, useParams } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import LoginRequireLayout from '../../layouts/LoginRequireLayout';


const VisualEditor = () => {
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const editorRef = useRef(null);
  const token = useSelector((state) => state.auth.token);
  const [modalVisible, setModalVisible] = useState(false);
  const [newPageModalVisible, setNewPageModalVisible] = useState(false);
  const [newPageName, setNewPageName] = useState('');
  const { id: projectId } = useParams();
  const menuRef = useRef(null);


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

  // Récupérez la valeur du paramètre "next"
  const type = queryParams.get('type');
  useEffect(() => {
    // Initialiser GrapesJS
    editorRef.current = grapesjs.init({
      container: '#gjs',
      plugins: [gjsPresetWebpage, gjsForms, gjsGoogleMaterialIcons, plugin],
      pluginsOpts: {
        "gjs-google-material-icons": {}
      },
      fromElement: true,
      height: '92%',
      width: '100%',
      storageManager: { type: 'inline' },
      panels: { defaults: [] },
      blockManager: {
        // appendTo: '.blocks',
        blocks: [
          {
            id: 'section',
            label: '<b>Section</b>',
            attributes: { class:'gjs-block-section' },
            content: `<section>
                        <h1>This is a simple title</h1>
                        <p>This is just a Lorem text: Lorem ipsum dolor sit amet</p>
                      </section>`,
          },
          {
            id: 'two-columns',
            label: '2 Columns',
            content: `<div class="row">
                        <div class="col-md-6">Column 1</div>
                        <div class="col-md-6">Column 2</div>
                      </div>`,
          },
          {
            id: 'three-columns',
            label: '3 Columns',
            content: `<div style="display:flex; flex-direction:row; justify-content:space-between;">
                        <div class="col-md-4">Column 1</div>
                        <div class="col-md-4">Column 2</div>
                        <div class="col-md-4">Column 3</div>
                      </div>`,
          },
          {
            id: 'four-columns',
            label: '4 Columns',
            content: `<div class="row">
                        <div class="col-md-3">Column 1</div>
                        <div class="col-md-3">Column 2</div>
                        <div class="col-md-3">Column 3</div>
                        <div class="col-md-3">Column 4</div>
                      </div>`,
          },
          {
            id: 'image',
            label: 'Image',
            content: { type: 'image' },
          },{
            id: 'contact-form',
            label: 'contact-form',
            content: `<div style="max-width: 600px; margin: auto; background: white; padding: 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
                          <h2 style="text-align: center;">Contactez-nous</h2>
                          <form action="/envoyer" method="post">
                              <div style="margin-bottom: 15px;">
                                  <label for="nom" style="display: block; margin-bottom: 5px;">Nom</label>
                                  <input type="text" id="nom" name="nom" style="width: 100%; padding: 8px; border: 1px solid #ccc; border-radius: 4px;" required>
                              </div>
                              <div style="margin-bottom: 15px;">
                                  <label for="email" style="display: block; margin-bottom: 5px;">E-mail</label>
                                  <input type="email" id="email" name="email" style="width: 100%; padding: 8px; border: 1px solid #ccc; border-radius: 4px;" required>
                              </div>
                              <div style="margin-bottom: 15px;">
                                  <label for="message" style="display: block; margin-bottom: 5px;">Message</label>
                                  <textarea id="message" name="message" rows="5" style="width: 100%; padding: 8px; border: 1px solid #ccc; border-radius: 4px; resize: vertical;" required></textarea>
                              </div>
                              <div style="margin-bottom: 15px;">
                                  <button type="submit" style="background-color: #0284c7; color: white; padding: 10px 15px; border: none; border-radius: 4px; cursor: pointer; width: 100%;">Envoyer</button>
                              </div>
                          </form>
                      </div>`,
          },{
            id: 'blog',
            label: 'blog',
            content: `
                      <h1 style="text-align: center;">Articles de Blog</h1>

                      <div style="max-width: 800px; margin: auto;">

                          <div style="background: white; padding: 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); margin-bottom: 20px;">
                              <h2 style="margin-top: 0;">Titre de l'Article 1</h2>
                              <img src="https://via.placeholder.com/600x300" alt="Image de l'article 1" style="width: 100%; border-radius: 4px;">
                              <p style="color: #555;">Ceci est un extrait de l'article 1. Vous pouvez en savoir plus sur ce sujet fascinant en lisant l'article complet.</p>
                              <a href="#" style="background-color: #0284c7; color: white; padding: 10px 15px; text-decoration: none; border-radius: 4px; display: inline-block;">En savoir plus</a>
                          </div>

                          <div style="background: white; padding: 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); margin-bottom: 20px;">
                              <h2 style="margin-top: 0;">Titre de l'Article 2</h2>
                              <img src="https://via.placeholder.com/600x300" alt="Image de l'article 2" style="width: 100%; border-radius: 4px;">
                              <p style="color: #555;">Ceci est un extrait de l'article 2. Continuez à explorer notre blog pour d'autres articles intéressants.</p>
                              <a href="#" style="background-color: #0284c7; color: white; padding: 10px 15px; text-decoration: none; border-radius: 4px; display: inline-block;">En savoir plus</a>
                          </div>

                          <div style="background: white; padding: 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
                              <h2 style="margin-top: 0;">Titre de l'Article 3</h2>
                              <img src="https://via.placeholder.com/600x300" alt="Image de l'article 3" style="width: 100%; border-radius: 4px;">
                              <p style="color: #555;">Ceci est un extrait de l'article 3. Ne manquez pas nos récentes publications !</p>
                              <a href="#" style="background-color: #0284c7; color: white; padding: 10px 15px; text-decoration: none; border-radius: 4px; display: inline-block;">En savoir plus</a>
                          </div>

                      </div>`,
          },{
            id: 'title_section1',
            label: 'section de titres',
            content: `
                      <div class="section-titres" style="font-family: Arial, sans-serif; background-color: #f0f0f0; margin: 0; padding: 20px;background-color: #fff; border-radius: 5px; padding: 20px; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
                          <h1 style="color: #333; font-size: 24px;">Titre Principal</h1>
                          <h2 style="color: #666; font-size: 20px;">Sous-titre 1</h2>
                          <h3 style="color: #999; font-size: 18px;">Détails du sous-titre 1</h3>

                          <h2 style="color: #666; font-size: 20px;">Sous-titre 2</h2>
                          <h3 style="color: #999; font-size: 18px;">Détails du sous-titre 2</h3>
                      </div>`,
          },{
            id: 'title_section2',
            label: 'section de titres 2',
            content: `
                      <div class="section-titres" style="font-family: 'Verdana', sans-serif; background-color: #f4f6f8; margin: 0; padding: 30px; color: #333;background-color: #fff; border-radius: 10px; padding: 40px; box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);">
                          <h1 style="font-size: 30px; font-weight: bold; color: #2980b9; text-align: center; margin-bottom: 20px; border-bottom: 3px solid #3498db; padding-bottom: 10px;">Titre Principal Élégant</h1>
                          
                          <div style="margin-bottom: 30px;">
                              <h2 style="font-size: 24px; font-weight: normal; color: #27ae60; margin-bottom: 10px;">Sous-titre 1</h2>
                              <h3 style="font-size: 20px; font-weight: normal; color: #7f8c8d; margin-bottom: 10px;">Détails enrichissants sur le sous-titre 1</h3>
                              <p style="font-size: 16px; color: #555;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt, nulla vel scelerisque feugiat, felis nisi malesuada nisl, in feugiat purus arcu quis ligula.</p>
                          </div>
                          
                          <div style="margin-bottom: 30px;">
                              <h2 style="font-size: 24px; font-weight: normal; color: #e67e22; margin-bottom: 10px;">Sous-titre 2</h2>
                              <h3 style="font-size: 20px; font-weight: normal; color: #7f8c8d; margin-bottom: 10px;">Détails captivants sur le sous-titre 2</h3>
                              <p style="font-size: 16px; color: #555;">Curabitur nec ligula at urna cursus tristique. Sed tempus, purus id facilisis cursus, magna dolor suscipit lorem, et pretium nibh magna quis sapien.</p>
                          </div>
                          
                          <div style="margin-bottom: 30px;">
                              <h2 style="font-size: 24px; font-weight: normal; color: #c0392b; margin-bottom: 10px;">Sous-titre 3</h2>
                              <h3 style="font-size: 20px; font-weight: normal; color: #7f8c8d; margin-bottom: 10px;">Détails intéressants sur le sous-titre 3</h3>
                              <p style="font-size: 16px; color: #555;">Phasellus pellentesque felis sit amet tortor condimentum, nec faucibus lacus suscipit. Aenean bibendum, nisl ut tempus felis, nunc tellus tristique risus.</p>
                          </div>
                      </div>`,
          },{
            id: 'nav',
            label: 'menu',
            content: `
                          
                          <style>
                              body {
                                  margin: 0;
                                  font-family: 'Roboto', sans-serif;
                              }
                              nav {
                                  background-color: #30475e; /* Couleur de fond sombre */
                                  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Ombre portée améliorée */;
                                  width:100%;
                              }
                              ul {
                                  list-style-type: none;
                                  padding: 0;
                                  margin: 0;
                                  display: flex;
                              }
                              li {
                                  position: relative;
                              }
                              .menu-item {
                                  display: flex;
                                  align-items: center; /* Alignement centré pour les icônes */
                                  color: #f0f0f0;
                                  padding: 15px 20px; /* Espacement amélioré */
                                  text-decoration: none;
                                  transition: background-color 0.3s, color 0.3s; /* Animation lors du survol */
                              }
                              .menu-item i {
                                  margin-right: 10px; /* Espacement entre l'icône et le texte */
                              }
                              .menu-item:hover {
                                  background-color: #1b1b2a; /* Couleur de survol plus sombre */
                                  color: #ececec; /* Couleur de texte lors du survol */
                              }
                              .sous-menu {
                                  display: none;
                                  position: absolute;
                                  left: 0;
                                  top: 100%;
                                  background-color: #374151; /* Couleur de fond du sous-menu */
                                  z-index: 1;
                                  min-width: 200px;
                                  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Ombre pour le sous-menu */
                              }
                              .sous-menu li {
                                  position: relative;
                              }
                              .sous-menu a {
                                  padding: 10px;
                                  display: flex;
                                  align-items: center; /* Alignement centré pour les icônes */
                                  color: #f0f0f0;
                                  text-decoration: none;
                                  transition: background-color 0.3s; /* Animation de survol pour le sous-menu */
                              }
                              .sous-menu a i {
                                  margin-right: 8px; /* Espacement entre l'icône et le texte */
                              }
                              .sous-menu a:hover {
                                  background-color: #575757; /* Couleur de survol du sous-menu */
                              }
                              /* Affiche le sous-menu au survol */
                              li:hover .sous-menu {
                                  display: block;
                              }
                          </style>
      

                      <nav>
                          <ul>
                              <li>
                                  <a href="#" class="menu-item"><i class="fas fa-home"></i>Home</a>
                                  <ul class="sous-menu">
                                      <li><a href="#"><i class="fas fa-plus"></i>Sous-élément 1.1</a></li>
                                      <li><a href="#"><i class="fas fa-plus"></i>Sous-élément 1.2</a></li>
                                      <li><a href="#"><i class="fas fa-plus"></i>Sous-élément 1.3</a></li>
                                      <li><a href="#"><i class="fas fa-plus"></i>Sous-élément 1.4</a></li>
                                  </ul>
                              </li>
                              <li>
                                  <a href="#" class="menu-item"><i class="fas fa-cog"></i>Settings</a>
                                  <ul class="sous-menu">
                                      <li><a href="#"><i class="fas fa-cog"></i>Sous-élément 2.1</a></li>
                                      <li><a href="#"><i class="fas fa-cog"></i>Sous-élément 2.2</a></li>
                                      <li><a href="#"><i class="fas fa-cog"></i>Sous-élément 2.3</a></li>
                                      <li><a href="#"><i class="fas fa-cog"></i>Sous-élément 2.4</a></li>
                                  </ul>
                              </li>
                              <li>
                                  <a href="#" class="menu-item"><i class="fas fa-user"></i>Costumers</a>
                                  <ul class="sous-menu">
                                      <li><a href="#"><i class="fas fa-user"></i>Sous-élément 3.1</a></li>
                                      <li><a href="#"><i class="fas fa-user"></i>Sous-élément 3.2</a></li>
                                      <li><a href="#"><i class="fas fa-user"></i>Sous-élément 3.3</a></li>
                                      <li><a href="#"><i class="fas fa-user"></i>Sous-élément 3.4</a></li>
                                  </ul>
                              </li>
                              <li>
                                  <a href="#" class="menu-item"><i class="fas fa-envelope"></i>Messages</a>
                                  <ul class="sous-menu">
                                      <li><a href="#"><i class="fas fa-envelope"></i>Sous-élément 4.1</a></li>
                                      <li><a href="#"><i class="fas fa-envelope"></i>Sous-élément 4.2</a></li>
                                      <li><a href="#"><i class="fas fa-envelope"></i>Sous-élément 4.3</a></li>
                                      <li><a href="#"><i class="fas fa-envelope"></i>Sous-élément 4.4</a></li>
                                  </ul>
                              </li>
                          </ul>
                      </nav>
`,
          },{
            id: 'slide',
            label: 'slide',
            content: `

    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: Arial, sans-serif;
            overflow: hidden;
        }

        .slider {
            position: absolute;
            width: 100%;
            height: 100vh;
            overflow: hidden;
            padding:5px;
            margin-bottom:5px;
        }

        .slides {
            display: flex;
            width: 300%; /* 3 slides */
            height: 100%;
            animation: slide 9s infinite; /* Durée totale pour 3 slides */
        }

        @keyframes slide {
            0% { transform: translateX(0); }
            33.33% { transform: translateX(-33%); }
            66.66% { transform: translateX(-66%); }
            99.99% { transform: translateX(0%); }
        }

        .slide {
            width: 100vw; /* 1 slide at a time */
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            background-size: cover;
            background-position: center;
        }

        .title {
            font-size: 2.5em;
            margin: 10px 0;
        }

        .text {
            font-size: 1.2em;
            text-align: center;
            max-width: 600px;
        }
    </style>


<span class="slider">
    <div class="slides">
        <div class="slide" style="background-image: url('https://via.placeholder.com/800x600/FF5733/FFFFFF?text=Slide+1');">
            <h1 class="title">Titre 1</h1>
            <p class="text">Ceci est le texte du premier slide.</p>
        </div>
        <div class="slide" style="background-image: url('https://via.placeholder.com/800x600/33FF57/FFFFFF?text=Slide+2');">
            <h1 class="title">Titre 2</h1>
            <p class="text">Ceci est le texte du deuxième slide.</p>
        </div>
        <div class="slide" style="background-image: url('https://via.placeholder.com/800x600/3357FF/FFFFFF?text=Slide+3');">
            <h1 class="title">Titre 3</h1>
            <p class="text">Ceci est le texte du troisième slide.</p>
        </div>
    </div>
</span>

            `,
          },
        ],
      },

    });

    // Charger les pages du projet au démarrage
    loadPages();

    return () => {
      editorRef.current.destroy();
    };
  }, []);

  const loadPages = async () => {
    try {
      const response = await axios.get(`/${ type === "template" ? "templates" :"projects"}/${projectId}/pages-list/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      setPages(response.data);
      setSelectedPage(response.data[0]);
      loadPageContent(response.data[0]?.id)
    } catch (error) {
      console.error('Erreur lors du chargement des pages:', error);
    }
  };

  useEffect(() => {
    if (selectedPage) {
      // Charger le contenu de la page sélectionnée
      loadPageContent(selectedPage.id);
    }
  }, [ loadPages, selectedPage]);

  const loadPageContent = async (pageId) => {
    try {
      const response = await axios.get(`/page/${pageId}/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      
          const { html_content, css_content, js_content } = response.data;
          editorRef.current.setComponents(html_content);
          editorRef.current.setStyle(css_content);
          
          

          editorRef.Storage.add('inline', {
            load() {
              return JSON.parse(html_content || '{}');
            },
            store(data) {
              const component = editorRef.Pages.getSelected().getMainComponent();
              const test = JSON.stringify(data);
              const test2 = `<html>
                <head>
                  <style>${editorRef.getCss({ component })}</style>
                </head>
                ${editorRef.getHtml({ component })}
              <html>`;
              console.log(test);
              console.log(test2);
              
              
            }
          });



    } catch (error) {
      console.error('Erreur lors du chargement du contenu de la page:', error);
    }
  };

  const savePageContent = async (id) => {
    if (selectedPage) {
      try {
        const htmlContent = editorRef.current.getHtml();
        const cssContent = editorRef.current.getCss();
        // JS content can be handled similarly if you want to capture custom JS
        await axios.patch(`/pages/${id}/`, {
          html_content: htmlContent,
          css_content: cssContent,
          js_content: editorRef.current.getJs(), // Include js_content if necessary
          
        }, {
          headers: { Authorization: `Bearer ${token.access}` },
        });
      } catch (error) {
        console.error('Erreur lors de la sauvegarde de la page:', error);
      }
    }
  };
  const menuItems = [
    {
        label: 'Renommer',
        command: () => setModalVisible(true),
    },
    {
        label: 'Supprimer',
        command: () => handleDeletePage(selectedPage?.id),
    },
    {
      label: 'Sauvegarder',
      command: () => savePageContent(selectedPage?.id),
    }
];

  const handlePageSelect = (page) => {
    setSelectedPage(page);
  };

  const handleAddPage = async () => {
    if (newPageName) {
      try {
        let data = { name: newPageName,  html_content:"<h1>"+newPageName+"</h1>", css_content:"" }
        if (type === "template") {
          data = {...data, templte:projectId }
        } else {
          data = {...data, project:projectId }
        }
        const response = await axios.post(`/${ type==="template"?"templates": "projects"}/${projectId}/pages/`, data, {
          headers: { Authorization: `Bearer ${token.access}` },
        });
        const newPage = response.data;
        setPages([...pages, newPage]);
        setSelectedPage(newPage);
        setNewPageModalVisible(false);
      } catch (error) {
        console.error('Erreur lors de la création de la page:', error);
      }
    }
  };

  const handleRenamePage = async (pageId) => {
    if (newPageName) {
      try {
        const response = await axios.patch(`/pages/${pageId}/`, { name: newPageName }, {
          headers: { Authorization: `Bearer ${token.access}` },
        });
        const updatedPage = response.data;
        setPages(pages.map(page => (page.id === pageId ? updatedPage : page)));
        setSelectedPage(updatedPage);
      } catch (error) {
        console.error('Erreur lors du renommage de la page:', error);
      }
    }
  };

  const handleDeletePage = async (pageId) => {
    try {
      await axios.delete(`/pages/${pageId}/delete/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      setPages(pages.filter(page => page.id !== pageId));
      setSelectedPage(null);
    } catch (error) {
      console.error('Erreur lors de la suppression de la page:', error);
    }
  };

  const openContextMenu = (e, page) => {
    e.preventDefault();
    menuRef.current.toggle(e);
    setSelectedPage(page);
  };

  const closeContextMenu = () => {
    const menu = document.getElementById('context-menu');
    menu.classList.remove('active');
  };

  return (
    <MainLayout>
      <LoginRequireLayout></LoginRequireLayout>
      <div className={styles.editorContainer}>
        <div className={styles.sidebar}>
          <h2 className={styles.sidebarTitle}>Pages du projet</h2>
          <ul className={styles.pageList}>
            {pages.map((page, index) => (
              <li
                key={index}
                className={`${styles.pageItem} ${selectedPage?.id === page.id ? styles.activePage : ''}`}
                onContextMenu={(e) => openContextMenu(e, page)}
                onClick={() => handlePageSelect(page)}
              >
                {page.name}
              </li>
            ))}
          </ul>
          <Button className={styles.addPageButton} onClick={e=>{setNewPageName('');setNewPageModalVisible(!newPageModalVisible)}}>Ajouter une page</Button>
        </div>

        <div className={styles.editorArea}>
          <div id="gjs" style={{ height: '100vh', overflow: 'hidden', color:"white" }}>
            <h1 className={styles.pageTitle}>{selectedPage?.name}</h1>
          </div>
        </div>



        {/* Menu contextuel */}
        <div id="context-menu" className={styles.contextMenu}>
        </div>
        <div className="card flex justify-content-center">
        <div>
            {/* <Menu  style={{width:"170px"}} model={menuItems} popup ref={menuRef} /> */}
            <Menu model={[
              {
                label: 'Renommer', icon: 'pi pi-pencil', command: () => { setNewPageName(selectedPage?.name || ''); setModalVisible(true); }
              },
              {
                label: 'Supprimer', icon: 'pi pi-trash', command: () => { handleDeletePage(selectedPage?.id); }
              },
              {
                label: 'Sauvegarder', icon: 'pi pi-save', command: () => { savePageContent(selectedPage?.id); }
              }
            ]} popup ref={menuRef} />
        </div>
        </div>

        {/* Modal de renommage */}
        <Dialog header="Renommer la page" visible={modalVisible} onHide={() => setModalVisible(false)}>
          <input
            type="text"
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            placeholder="Nouveau nom de page"
          />
          <Button onClick={() => { handleRenamePage(selectedPage?.id); setModalVisible(false); }}>Renommer</Button>
        </Dialog>


         {/* Modal d'ajout */}
         <Dialog header="Nouvelle page" visible={newPageModalVisible} onHide={() => setNewPageModalVisible(false)} >
          <div  style={{display:"flex", flexDirection:"column", justifyContent:"space-between", gap:"20px"}}>
            <InputText
            type="text"
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            placeholder="Nom de page"
          />
          <Button onClick={() => { handleAddPage();  }}>Enregistrer</Button>
          </div>
        </Dialog>
      </div>
    </MainLayout>
  );
};

export default VisualEditor;
