import {  GoogleLogin } from '@react-oauth/google';
// import { GitHubLogin } from '@react-oauth/github';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import styles from './AuthLayout.module.css';
import axios from '../../utils/axiosConfig';
import { setAuth } from '../../utils/redux/slices/authSlice';
import { useRef } from 'react';
export default function AuthLayout({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);  
  const location = useLocation();
  const handleGoogleSuccess = async (response) => {
    try {

      // Créez une instance de URLSearchParams avec la query string de l'URL
      const queryParams = new URLSearchParams(location.search);

      // Récupérez la valeur du paramètre "next"
      const nextUrl = queryParams.get('next');
      const token = response.credential; // Google OAuth token
      console.log(token);
      
      const backendResponse = await axios.post('/users/social-login/google/', { token, provider:"google" });
      // Handle backend response (e.g., store token and user profile in your Redux store)
      dispatch(setAuth({
        token: backendResponse.data.token,
        permissions: backendResponse.data.permissions,
        profile: backendResponse.data.profile,
      }));
      toast.current.show({ severity:'success', summary: 'Succès', detail: 'Connexion réussie', life: 3000 });
      navigate('/admin/'); // Redirect the user after successful login
      console.log('Backend Response:', backendResponse.data);
      // Redirect the user after successful login
        setTimeout(() => {
          if (nextUrl) {
              navigate(nextUrl);
          } else {
              navigate('/admin/');
          }
        }, 2000);
    } catch (error) {
      console.error('Backend Google Login Failed:', error);
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la connexion avec Google', life: 5000 });
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google Login Failed:', error);
  };

  // const handleGitHubSuccess = (response) => {
  //   console.log('GitHub Login Success:', response);
  //   // Handle login success (e.g., send token to server)
  // };

  // const handleGitHubFailure = (error) => {
  //   console.error('GitHub Login Failed:', error);
  // };

  return (
    <div className={styles.container}>
      <Toast ref={toast} />
      <img src="/logo.png" alt="LegionBuild" width={150} height={50} />
      <h2 className={styles.title}>Bienvenue !! </h2>
      <p>Continuer avec: </p>
      
      <div className={styles.socialLogin}>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            render={(renderProps) => (
              <button onClick={renderProps.onClick} disabled={renderProps.disabled} className={styles.socialButton}>
                <img style={{ marginRight: "10px" }} src="/img/Google__G__logo.svg" alt="Google" width={20} height={20} />
                Google
              </button>
            )}
          />
        
        {/* <span>ou</span>
        
        <GitHubLogin
          clientId="Ov23licA27RWlrkh0NX6"
          onSuccess={handleGitHubSuccess}
          onFailure={handleGitHubFailure}
          buttonText="GitHub"
          className={styles.socialButton}
          render={(renderProps) => (
            <button onClick={renderProps.onClick} className={styles.socialButton}>
              <img style={{ marginRight: "10px" }} src="/img/Octicons-mark-github.svg" alt="GitHub" width={20} height={20} />
              GitHub
            </button>
          )}
        /> */}
      </div>
      
      {children}
    </div>
  );
}
