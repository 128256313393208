
import React, { useState, useEffect, useRef } from 'react';
import axios, { baseURL } from '../../utils/axiosConfig';
import MainLayout from "../../layouts/MainLayout";
import LoginRequiredLayout from '../../layouts/LoginRequireLayout';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './profile.css';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const Profile = () => {
    const profile = useSelector((state) => state.auth.profile);
    const [userInfo, setUserInfo] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const toast = useRef();
    // Schéma de validation pour le formulaire de mise à jour du profil
    const profileSchema = yup.object().shape({
        first_name: yup.string().required("Le prénom est obligatoire"),
        last_name: yup.string().required("Le nom est obligatoire"),
        email: yup.string().email("Email invalide").required("L'email est obligatoire"),
        profession: yup.string().required("Veuillez remplir votre profession"),
    });

    // Schéma de validation pour le changement de mot de passe
    const passwordSchema = yup.object().shape({
      oldPassword: yup.string().notRequired(),
        newPassword: yup.string().min(6, "Le mot de passe doit contenir au moins 6 caractères").required("Le nouveau mot de passe est obligatoire"),
        confirmPassword: yup.string()
            .oneOf([yup.ref('newPassword'), null], 'Les mots de passe doivent correspondre')
            .required("Veuillez confirmer votre mot de passe"),
    });

    const { register, handleSubmit,setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(profileSchema),
        defaultValues: userInfo,
    });

    const { register: registerPassword, handleSubmit: handlePasswordSubmit, formState: { errors: passwordErrors }, reset: resetPassword } = useForm({
        resolver: yupResolver(passwordSchema),
    });

    const fetchUserInfo = async () => {
      try {
          const response = await axios.get(`/users/${profile.id}`, {
              headers: {
                  'Authorization': `Bearer ${token.access}`
              }
          });
          setUserInfo(response.data);
          Object.keys(response.data).forEach(key => {
            setValue(key, response.data[key]);
          });
      } catch (error) {
          console.error("Erreur lors de la récupération des informations utilisateur", error);
      }
  };

    useEffect(() => {
        
        fetchUserInfo();
    }, [profile.id, reset, token.access, setValue]);

    const handleUpdateProfile = async (data) => {
        try {
            await axios.put(`/users/update/${profile.id}/`, data,  {
              headers: {
                Authorization: `Bearer ${token.access}`,
              }
            });
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Operation de mise à jour réussie !', life: 3000 });
            fetchUserInfo();
            setEditMode(false);
            
        } catch (error) {
            console.error("Erreur lors de la mise à jour du profil", error);
        }
    };

    const handlePasswordChange = async (data) => {
        try {
            await axios.post(`/users/change-password/`, data);
            toast.current.show({ severity:'success', summary: 'Succès', detail: 'Mot de passe mis à jour avec succès', life: 3000 });
            setShowPasswordDialog(false);
            resetPassword();
        } catch (error) {
            console.error("Erreur lors de la mise à jour du mot de passe", error);
            toast.current.show({ severity:'danger', summary: 'Erreur', detail: 'Quelque chose a mal tourné lors de la mise à jour ', life: 3000 })
        }
    };

    const handleEditToggle = () => {
        setEditMode(!editMode);
    };

    const handleDeleteAccount = async () => {
        try {
            await axios.delete(`/users/delete/${profile.id}/`);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Operation de mise à jour réussie !', life: 3000 });
            navigate("/");
            // Rediriger ou effectuer une autre action après la suppression du compte
        } catch (error) {
            console.error("Erreur lors de la suppression du compte", error);
        }
    };

    const handleUpdateProfilePicture = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        try {
            await axios.post(`/users/update_profile_picture/`, formData, {
                headers: {
                    'Authorization': `Bearer ${token.access}`,
                    'Content-Type': 'multipart/form-data',
                }
            });
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Operation de mise à jour réussie !', life: 3000 });
            // Mettre à jour l'image de profil après la mise à jour
        } catch (error) {
            toast.current.show({ severity: 'danger', summary: 'Erreur', detail: 'Operation de mise à jour  non réussie ..', life: 3000 });
            console.error("Erreur lors de la mise à jour de la photo de profil", error);
        }
    };

    const handleDeleteProfilePicture = async () => {
        try {
            await axios.delete(`/users/delete_profile_picture/`);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Operation  réussie !', life: 3000 });

            // Mettre à jour l'état de l'image après suppression
        } catch (error) {
            console.error("Erreur lors de la suppression de la photo de profil", error);
            toast.current.show({ severity: 'danger', summary: 'Erreur', detail: 'Operation de suppression  non réussie ..', life: 3000 });
        }
    };

    if (!userInfo) {
        return <LoginRequiredLayout> <MainLayout><div>Chargement...</div></MainLayout></LoginRequiredLayout>;
    }

    return (
        <LoginRequiredLayout>
            <MainLayout>
              <Toast ref={toast}/>
                <div className="profile-container">
                    <div className="profile-header">
                        <div className="profile-photo-container">
                            <img src={`${baseURL}${userInfo.image}`} alt="User de profil" className="profile-photo" />
                            <Button icon="pi pi-pencil" className="profile-photo-edit" onClick={() => document.getElementById('profile-pic-upload').click()} />
                            <Button icon="pi pi-trash" className="profile-photo-delete" onClick={handleDeleteProfilePicture} />
                            <input type="file" id="profile-pic-upload" style={{ display: 'none' }} onChange={(e) => handleUpdateProfilePicture(e.target.files[0])} />
                        </div>
                        <h1>{userInfo.first_name}  {userInfo.last_name}</h1>
                        <small>{userInfo.profession || "Profession non indiquée"}  </small>
                        <div className="profile-details">
                            <p><b>Email: </b> {userInfo.email}</p>
                            <p><b>Nom d'utilisateur: </b> {userInfo.username}</p>
                            <p><b>Rejoint le: </b> {new Date(userInfo.created_at).toLocaleString()}</p>
                            <p><b>Dernière connexion: </b> {new Date(userInfo.last_login).toLocaleString()}</p>
                        </div>
                        <Button label="Modifier le profil" className="edit-button" onClick={(e)=>{handleEditToggle(); navigate("#update-profile")}} />
                        <Button label="Changer le mot de passe" className="edit-button" onClick={() => setShowPasswordDialog(true)} />
                        <Button label="Supprimer le compte" className="delete-button" onClick={()=>setShowConfirmDialog(true)} />
                    </div>

                    <div className="profile-projects">
                        <h2>Mes Projets</h2>
                        <ul>
                            <li>Sites web: {userInfo.webProjectsCount}</li>
                            <li>Applications web: {userInfo.webAppsCount}</li>
                            <li>Applications mobiles: {userInfo.mobileAppsCount}</li>
                        </ul>
                    </div>

                    {editMode && (
                        <div className="edit-profile">
                            <h3 id='update-profile'>Modifier les informations</h3>
                            <form onSubmit={handleSubmit(handleUpdateProfile)} style={{marginBlock:"5rem"}}>
                                <div className="p-field">
                                    <label htmlFor="first_name">Prénom:</label>
                                    <InputText id="first_name" {...register('first_name')} className={`input-field ${errors.first_name ? 'p-invalid' : ''}`} />
                                    {errors.first_name && <small className="p-error">{errors.first_name.message}</small>}
                                </div>
                                <div className="p-field">
                                    <label htmlFor="last_name">Nom:</label>
                                    <InputText id="last_name" {...register('last_name')} className={`input-field ${errors.last_name ? 'p-invalid' : ''}`} />
                                    {errors.last_name && <small className="p-error">{errors.last_name.message}</small>}
                                </div>
                                <div className="p-field">
                                    <label htmlFor="last_name">profeesion</label>
                                    <select id="profession" {...register('profession')} className={`input-field ${errors.last_name ? 'p-invalid' : ''}`} >
                                      <option value="">Sélectionner la profession</option>
                                      <option value="Developpeur">Développeur</option>
                                      <option value="Entrepreneur">Entrepreneur</option>
                                      <option value="Designer">Designer</option>
                                      <option value="Consultant">Consultant</option>
                                      <option value="Marketeur">Marketeur</option>
                                      <option value="Freelancer">Freelancer</option>
                                      <option value="Etudiant">Étudiant</option>
                                      <option value="Autre">Autre</option>
                                    </select>
                                    {errors.profession && <small className="p-error">{errors.profession.message}</small>}
                                </div>
                                <div className="p-field">
                                    <label htmlFor="email">Email:</label>
                                    <InputText id="email" {...register('email')} className={`input-field ${errors.email ? 'p-invalid' : ''}`} />
                                    {errors.email && <small className="p-error">{errors.email.message}</small>}
                                </div>
                                <Button type="submit" label="Mettre à jour" className="update-button" />
                            </form>
                        </div>
                    )}

                    <Dialog header="Changer le mot de passe" visible={showPasswordDialog} onHide={() => setShowPasswordDialog(false)}>
                        <form onSubmit={handlePasswordSubmit(handlePasswordChange)}>
                        {!userInfo.has_unusable_password && (<div className="p-field">
                                <label htmlFor="oldPassword">Ancien mot de passe:</label>
                                <Password id="oldPassword" {...registerPassword('oldPassword')} feedback={false} className={` ${passwordErrors.oldPassword ? 'p-invalid' : ''}`} toggleMask/>
                                {passwordErrors.oldPassword && <><br /><small className="p-error">{passwordErrors.oldPassword.message}</small></>}
                            </div>)}
                            <div className="p-field">
                                <label htmlFor="newPassword">Nouveau mot de passe:</label>
                                <Password id="newPassword" {...registerPassword('newPassword')} className={`${passwordErrors.newPassword ? 'p-invalid' : ''}` } toggleMask />
                                {passwordErrors.newPassword && <><br /><small className="p-error">{passwordErrors.newPassword.message}</small></>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="confirmPassword">Confirmer le mot de passe:</label>
                                <Password id="confirmPassword" {...registerPassword('confirmPassword')} feedback={false} className={`${passwordErrors.confirmPassword ? 'p-invalid' : ''}`} toggleMask/>
                                {passwordErrors.confirmPassword && <><br /><small className="p-error">{passwordErrors.confirmPassword.message}</small></>}
                            </div>
                            <Button type="submit" label="Changer le mot de passe" className="update-button" />
                        </form>
                    </Dialog>

                    <Dialog header="Attention" visible={showConfirmDialog} onHide={() => setShowConfirmDialog(false)}>
                        <h3>Voulez-vous vraiment supprimer votre compte ? <br/> Cette action est irreverssible ..</h3>
                            <Button label="Oui Je supprime" className="delete-button" onClick={handleDeleteAccount}/>
                    </Dialog>
                </div>
            </MainLayout>
        </LoginRequiredLayout>
    );
};

export default Profile;
