import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'http://localhost:8000',// https://api.build.legionweb.co
    headers: {
        'Content-Type': 'application/json',
    },
});
export const baseURL = 'http://localhost:8000';

export default axiosInstance;
