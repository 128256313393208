'use client'

import MainLayout from '../../layouts/MainLayout';
import {useNavigate} from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import axios from '../../utils/axiosConfig';
import React, { useEffect, useState } from 'react';
import '../admin.css'



const Accessoire = () => {
  const navigate = useNavigate();

  const profile = useSelector((state) => state.auth.profile);
  const [userInfo, setUserInfo] = useState(null);
  const token = useSelector((state) => state.auth.token);

  const {setValue, formState: { errors }} = useForm({
    defaultValues: userInfo,
  });


  return (
    <MainLayout>
      <div className='page1'>

      <div className='contain1'>

        
        <div class='line'>
        
          <div className='item'>
            <div className='headtampete'></div>
            
            <button onClick={()=> navigate("/admin/accessoires/tampletes")} className='text'><center><h3>Tampletes</h3></center></button>
          </div>

          <div className='item'>
            <div className='headnewtampete'></div>
            <button onClick={()=> navigate("/admin/accessoires/tampletes")} className='text'><center><h3>New Tamplete</h3></center></button>
          </div>

          <div className='item'>
            <div className='headdeletetampete'></div>
            <button onClick={()=> navigate("/admin/accessoires/delete")} className='text'><center><h3>Delete Tamplete</h3></center></button>
          </div>
        </div>



        <div class='line'>
        
          <div className='item'>
            <div className='headsites'></div>
            <button onClick={()=> navigate("/admin/sites")} className='text'><center><h3>Sites</h3></center></button>
          </div>

          <div className='item'>
            <div className='headappliweb'></div>
            <button onClick={()=> navigate("/admin/appliweb")} className='text'><center><h3>Applis Web</h3></center></button>
          </div>

          <div className='item'>
            <div className='headapplimobil'></div>
            <button onClick={()=> navigate("/admin/applimobil")} className='text'><center><h3>Applis Mobils</h3></center></button>
          </div>
        </div>
          
      </div>
      
    </div>
    </MainLayout>
    
  )
}
export default Accessoire;




