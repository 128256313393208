'use client'

import MainLayout from '../../../layouts/MainLayout';
import './revenus.css'
import { Bar , Doughnut} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';


const Revenus = () => {
  return (
    <MainLayout>
        <div className='page1'>

            <div className='contain1'>
            
            <div class='line'>
            
                <div className='item'>
                <div className='left_tampletes'></div>
                <div className='right'>
                    <div className='header'><center><h4>Tampletes</h4></center></div>
                    <div className='number'><center><h1>300 000  XAF</h1></center></div>
                </div>
                </div>

                

                <div className='item'>
                <div className='left_sites'></div>
                <div className='right'>
                    <div className='header'><center><h4>Composants pour sites web</h4></center></div>
                    <div className='number'><center><h1>245 425  XAF</h1></center></div>
                </div>
                </div>

            </div>



            <div class='line'>
            
                <div className='item'>
                <div className='left_applimobil'></div>
                <div className='right'>
                    <div className='header'><center><h4>Composants pour mobil</h4></center></div>
                    <div className='number'><center><h1>255 955  XAF</h1></center></div>
                </div>
                </div>



                <div className='item'>
                <div className='left_appliweb'></div>
                <div className='right'>
                    <div className='header'><center><h4>Composants pour Appli web</h4></center></div>
                    <div className='number'><center><h1>220 000  XAF</h1></center></div>
                </div>
                </div>
            </div>


            <div className='line'>
                <center>
                    <div className='total'><h2>TOTAL:  <span>1 021 380 XAF</span></h2></div>
                </center>
            </div>

            <div class='line'>
                <div className='diagrammes'>
                
                <div className='gauche'><BarChart/></div>
                <div className='droite'><PieChart/></div>
                </div>
                
            </div>

            
                
            
            
                
        </div>

    </div>
    </MainLayout>
    
  )
}







Chart.register(...registerables);

const BarChart = () => {
    const data = {
        labels: ['Site web', 'Applications Mobile', 'Applications Web'],
        datasets: [
            {
                label: 'Tampletes',
                data: [25, 15, 20],
                backgroundColor: ['#26E87D', '#E25C4B', '#14C2EE'],
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                hoverBackgroundColor: ['#4CEB86', '#FF6F61', '#00A3E0'],
                hoverBorderColor: 'rgba(255, 255, 255, 1)',
                hoverBorderWidth: 3
            }
        ]
    };

    const options = {
        responsive: true,
        animations: {
            tension: {
                duration: 1000,
                easing: 'linear',
                from: 25,
                to: 0,
                loop: true
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                },
                grid: {
                    display: false
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Quantités',
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                },
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    }
                }
            }
        }
    };

    return (
        <div>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
                Diagramme quantitatif des Templates
            </h2>
            <Bar data={data} options={options} />
        </div>
    );
};








Chart.register(...registerables);

const PieChart = () => {
    const data = {
        labels: ['Composants pour mobil', 'Composants pour appli web', 'Composants pour Sites'],
        datasets: [
            {
                label: 'Quantités',
                data: [50, 15, 35],
                backgroundColor: ['#26E87D', '#FE8F81', '#14C2EE'],
                borderColor: 'rgba(255, 255, 255, 1)',
                borderWidth: 2,
                hoverBackgroundColor: ['#4CEB86', '#FF6F61', '#00A3E0'],
                hoverBorderColor: 'rgba(255, 255, 255, 1)',
                hoverBorderWidth: 3
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        return `${context.label}: ${context.raw}`;
                    }
                }
            }
        }
    };

    return (
        <div>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
                Diagramme circulaire Quantitatif Composants
            </h2>
            <div style={{width:'350px',marginLeft:'20%'}} ><Doughnut data={data} options={options} /></div>
            
        </div>
    );
};

export default Revenus;