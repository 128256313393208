
import styles from './home.module.css';
import MainLayout from '../layouts/MainLayout';
import {useLocation, useNavigate} from 'react-router-dom'
import { useEffect } from 'react';
export default function HomePage() {
  const navigate = useNavigate();

  const location = useLocation();


  const queryParams = new URLSearchParams(location.search);

  // Récupérez la valeur du paramètre "next"
  const nextUrl = queryParams.get('next');

  useEffect(()=>{
    // Redirection après connexion réussie
    setTimeout(() => {
      if (nextUrl) {
        navigate(`${nextUrl}`, );
      } 
    }, 100);
  }, []);
  return (
    <MainLayout>
      <div className={styles.container}>
        <h1 className={styles.heading}>Bonjour ! Que voulez-vous créer aujourd’hui ??</h1>
        
        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.cardImage}>
              <img className={styles.img} src="/img/site-web.png" alt="Site web" fill objectFit="cover" />
              <div className={styles.overlay}>
                <span className={styles.span}>Site web</span>
              </div>
            </div>
            <button onClick={()=>{navigate("/login?next=/projects")}} className={styles.btn}>Se lancer</button>
          </div>

          <div className={styles.card}>
            <div className={styles.cardImage}>
              <img className={styles.img} src="/img/app-web.jpg" alt="Application web" fill objectFit="cover" />
              <div className={styles.overlay}>
                <span className={styles.span}>Application web</span>
              </div>
            </div>
            <button onClick={()=>{navigate("/login?next=/projects")}} className={styles.btn}>Se lancer</button>
          </div>

          <div className={styles.card}>
            <div className={styles.cardImage}>
              <img className={styles.img} src="/img/app-mobile.jpg" alt="Application mobile" fill objectFit="cover" />
              <div className={styles.overlay}>
                <span className={styles.span}>Application mobile</span>
              </div>
            </div>
            <button onClick={()=>{navigate("/login?next=/projects")}} className={styles.btn}>Se lancer</button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
