import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import styles from './login.module.css';
import {useNavigate, Link,  useLocation} from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import AuthLayout from '../../layouts/auth/AuthLayout';
import axios from '../../utils/axiosConfig';
import { setAuth } from '../../utils/redux/slices/authSlice';
import { useSelector } from 'react-redux';
// Page de connexion avec validation Yup et gestion du token d'authentification


// Schéma de validation avec Yup
const schema = yup.object().shape({
    username: yup.string()
        .required("Nom d'utilisateur est requis")
        .matches(/^[a-zA-Z0-9_]+$/, "Le nom d'utilisateur ne doit pas contenir d'espace ni de caractères spéciaux"),
    password: yup.string()
        .required("Mot de passe est requis")
        .min(4, 'Le mot de passe doit contenir au moins 4 caractères')
});

const LoginPage = () => {
    const dispatch = useDispatch();
    const toast = useRef(null);

    const {control,  register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const navigate  = useNavigate()
    const location = useLocation();
    const auth = useSelector((state) => state.auth);


    const queryParams = new URLSearchParams(location.search);

    // Récupérez la valeur du paramètre "next"
    const nextUrl = queryParams.get('next');
    const onSubmit = async (data) => {
        try {
            const response = await axios.post('/users/login/', data);
            dispatch(setAuth({
                token: response.data.token,
                permissions: response.data.permissions,
                profile: response.data.profile,
            }));

            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Connexion réussie', life: 3000 });
            // Redirection après connexion réussie
            setTimeout(() => {
                if (nextUrl) {
                    navigate(`${nextUrl}`, );
                } else {
                    navigate('/projects');
                }
              }, 2000);

        } catch (error) {
            console.error('Erreur lors de la connexion', error);
            if (error.response) {
                if (error.response.status === 400) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Mauvaise requête : un champ est requis', life: 5000 });
                } else if (error.response.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Informations incorrectes', life: 5000 });
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la connexion', life: 5000 });
            }
        }
    };


    // verify if the user is conneced then redirect to the next page
    useEffect(()=>{
        if (auth.token) {
            // verify the validity of the token
            axios.get('/users/token/verify', { headers: { Authorization: `Bearer ${auth.token.access}` } })
               .then(() => {
                    // if the token is valid then redirect to the next page
                    if (nextUrl) {
                        navigate(`${nextUrl}`, );
                    } else {
                        navigate('/projects');
                    }
                })
               .catch((error) => {
                    console.error('Erreur lors de la vérification du token', error);
                    toast.current?.show({ severity: 'error', summary: 'Erreur', detail: 'Token invalide', life: 5000 });
                    dispatch(setAuth({}));
                });

        }
    });

    return (
        <MainLayout>
            <AuthLayout>
                <Toast ref={toast} />
                    <h2 className={styles.title} style={{ margin: '20px', alignItems:"center" }}> Connexion</h2>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <label className={styles.label}>Veuillez entrer vos identifiants</label>
                        <div className={styles.formGroup}>
                            <span className="p-float-label">
                                <InputText 
                                    id="username" 
                                    {...register('username')} 
                                    className={styles.input} 
                                    placeholder="Nom d'utilisateur .."
                                />
                                {errors.username && <small className="p-error">{errors.username.message}</small>}
                            </span>
                        </div>
                        <div className={styles.formGroup}>
                            <span className="p-float-label">
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <Password
                                        id="password"
                                        {...field}
                                        className={styles.input1}
                                        feedback={false}
                                        placeholder="Mot de passe .."
                                        toggleMask
                                    />
                                )}
                            />
                                {errors.password && <small className="p-error">{errors.password.message}</small>}
                            </span>
                        </div>
                        <p>Pas  de comtpte  ? <Link to="/register" style={{color:"#0070f3"}}>Enregistrez-vous</Link></p>
                    
                        <Button type="submit" className={styles.submitBtn} label="Connexion" />
                    </form>
            </AuthLayout>
        </MainLayout>
    );
};

export default LoginPage;
