// 'use client'

// import MainLayout from '../layouts/MainLayout';
// import {useNavigate} from 'react-router-dom'
// import { useForm } from 'react-hook-form';
// import { useSelector } from 'react-redux';
// import axios from '../utils/axiosConfig';
// import React, { useEffect, useState } from 'react';
// import './admin.css'



// const Page = () => {
//   const navigate = useNavigate();

//   const profile = useSelector((state) => state.auth.profile);
//   const [userInfo, setUserInfo] = useState(null);
//   const token = useSelector((state) => state.auth.token);

//   const {setValue, formState: { errors }} = useForm({
//     defaultValues: userInfo,
//   });

//   useEffect(()=>{
//     async function isadmin () {
//       try {
//           const response = await axios.get(`/users/${profile.id}`, {
//               headers: {
//                   'Authorization': `Bearer ${token.access}`
//               }
//           });
//           setUserInfo(response.data);
//           Object.keys(response.data).forEach(key => {
//             setValue(key, response.data[key]);
//           });
//               if(userInfo.is_superuser != 1){
//                 navigate('/home');
//               }
  
//       } catch (error) {
//           console.error("Erreur lors de la récupération des informations utilisateur", error);
//       }
//     };
  
    
//     isadmin();
//   },[])

//   return (
//     <MainLayout>
//       <div className='page1'>

//       <div className='contain1'>

        
//         <div class='line'>
        
//           <div className='item'>
//             <div className='headstat'></div>
            
//             <button onClick={()=> navigate("/admin/statistiques")} className='text'><center><h3>Statistiques</h3></center></button>
//           </div>

//           <div className='item'>
//             <div className='headuser'></div>
//             <button onClick={()=> navigate("/admin/utilisateur")} className='text'><center><h3>Users</h3></center></button>
//           </div>

//           <div className='item'>
//             <div className='headsettings'></div>
//               <button onClick={()=> navigate("/admin/templates")} className='text'><center><h3>Templates</h3></center></button>
//           </div>
//         </div>



//         <div class='line'>
        
//           <div className='item'>
//             <div className='headsites'></div>
//             <button onClick={()=> navigate("/admin/sites")} className='text'><center><h3>Sites</h3></center></button>
//           </div>

//           <div className='item'>
//             <div className='headappliweb'></div>
//             <button onClick={()=> navigate("/admin/appliweb")} className='text'><center><h3>Applis Web</h3></center></button>
//           </div>

//           <div className='item'>
//             <div className='headapplimobil'></div>
//             <button onClick={()=> navigate("/admin/applimobil")} className='text'><center><h3>Applis Mobils</h3></center></button>
//           </div>
//         </div>
          
//       </div>
      
//     </div>
//     </MainLayout>
    
//   )
// }
// export default Page;







'use client'

import MainLayout from '../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import axios from '../utils/axiosConfig';
import React, { useEffect, useState } from 'react';
import './admin.css';

const Page = () => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.auth.profile);
  const [userInfo, setUserInfo] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const { setValue, formState: { errors } } = useForm({
    defaultValues: userInfo,
  });

  useEffect(() => {
    async function isadmin() {
      try {
        const response = await axios.get(`/users/${profile.id}`, {
          headers: {
            'Authorization': `Bearer ${token.access}`,
          },
        });
        setUserInfo(response.data);
        Object.keys(response.data).forEach(key => {
          setValue(key, response.data[key]);
        });
        if (userInfo.is_superuser != 1) {
          navigate('/home');
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations utilisateur", error);
      }
    };
    isadmin();
  }, []);

  return (
    <MainLayout>
      <div className='page1'>
        <div className='contain1'>
          <div className='grid-container'>
            <div className='item'>
              <div className='headstat'></div>
              <button onClick={() => navigate("/admin/statistiques")} className='text'>
                <center><h3>Statistiques</h3></center>
              </button>
            </div>

            <div className='item'>
              <div className='headuser'></div>
              <button onClick={() => navigate("/admin/utilisateur")} className='text'>
                <center><h3>Users</h3></center>
              </button>
            </div>

            <div className='item'>
              <div className='headsettings'></div>
              <button onClick={() => navigate("/admin/templates")} className='text'>
                <center><h3>Templates</h3></center>
              </button>
            </div>

            <div className='item'>
              <div className='headsites'></div>
              <button onClick={() => navigate("/admin/sites")} className='text'>
                <center><h3>Sites</h3></center>
              </button>
            </div>

            <div className='item'>
              <div className='headappliweb'></div>
              <button onClick={() => navigate("/admin/appliweb")} className='text'>
                <center><h3>Applis Web</h3></center>
              </button>
            </div>

            <div className='item'>
              <div className='headapplimobil'></div>
              <button onClick={() => navigate("/admin/applimobil")} className='text'>
                <center><h3>Applis Mobiles</h3></center>
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Page;
