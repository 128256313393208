'use client';
import React, { useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Link, useNavigate } from 'react-router-dom';
import styles from './register.module.css';
import axios from '../../utils/axiosConfig';
import MainLayout from '../../layouts/MainLayout';
import AuthLayout from '../../layouts/auth/AuthLayout';


// Schéma de validation avec Yup
const schema = yup.object().shape({
  first_name: yup.string().required("Le prénom est requis"),
  last_name: yup.string().required("Le nom est requis"),
  profession: yup.string().required("La profession est requise"),
  username: yup.string()
    .required("Nom d'utilisateur est requis")
    .matches(/^[a-zA-Z0-9_]+$/, "Le nom d'utilisateur ne doit pas contenir d'espace ni de caractères spéciaux"),
  password: yup.string()
    .required("Mot de passe est requis")
    .min(6, 'Le mot de passe doit contenir au moins 6 caractères')
    .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
    .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une minuscule')
    .matches(/[0-9]/, 'Le mot de passe doit contenir au moins un chiffre'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Les mots de passe doivent correspondre')
    .required('La confirmation du mot de passe est requise'),
});

const RegisterPage = () => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const toast = useRef(null);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      await axios.post('/users/register/', data);
      toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Inscription réussie', life: 3000 });
      setTimeout(() => {
        navigate('/auth/login');
      }, 3000);
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'inscription', life: 5000 });
    }
  };

  return (
      <MainLayout>
        <AuthLayout>
          <Toast ref={toast} />
          <div className={styles.container}>
            <h2 className={styles.title}>Formulaire d&apos;inscription</h2>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <div className={styles.row}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <InputText {...field} placeholder="Prénom.." className={styles.input} />
                  )}
                />
                {errors.first_name && <small className="p-error">{errors.first_name.message}</small>}
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <InputText {...field} placeholder="Nom.." className={styles.input} />
                  )}
                />
                {errors.last_name && <small className="p-error">{errors.last_name.message}</small>}
              </div>
              <Controller
                name="profession"
                control={control}
                render={({ field }) => (
                  <select {...field} className={styles.input}>
                    <option value="">Sélectionner la profession</option>
                    <option value="Developpeur">Développeur</option>
                    <option value="Entrepreneur">Entrepreneur</option>
                    <option value="Designer">Designer</option>
                    <option value="Consultant">Consultant</option>
                    <option value="Marketeur">Marketeur</option>
                    <option value="Freelancer">Freelancer</option>
                    <option value="Etudiant">Étudiant</option>
                    <option value="Autre">Autre</option>
                  </select>
                )}
              />
              {errors.profession && <small className="p-error">{errors.profession.message}</small>}
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <InputText {...field} placeholder="Nom d'utilisateur.." className={styles.input} />
                )}
              />
              {errors.username && <small className="p-error">{errors.username.message}</small>}
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Password {...field} placeholder="Mot de passe.." className={styles.input1} feedback={false} toggleMask />
                )}
              />
              {errors.password && <small className="p-error">{errors.password.message}</small>}
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <Password {...field} placeholder="Confirmer le mot de passe.." className={styles.input1} feedback={false} toggleMask />
                )}
              />
              {errors.confirmPassword && <small className="p-error">{errors.confirmPassword.message}</small>}
              <Button type="submit" label="Se lancer" className={styles.submitBtn} />
            </form>
            <p>Déjà inscrit ? <Link to="/login" style={{color:"#0070f3"}}>Connectez-vous</Link></p>
          </div>
        </AuthLayout>
      </MainLayout>
  );
};

export default RegisterPage;
