import {BrowserRouter, Routes, Route } from 'react-router-dom'
import { store, persistor } from './utils/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider} from 'react-redux';
import {  GoogleOAuthProvider } from '@react-oauth/google';
import Page from './admin'
import Applimobil from './admin/applimobil';
import Appliweb from './admin/appliweb';
import Users from './admin/utilisateur';
import Sites from './admin/sites'
import Statistiques from './admin/statistiques';
import Revenus from './admin/statistiques/revenus';

//import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import LoginPage from './pages/auth/login';
import RegisterPage from './pages/auth/register';
import Projects from './pages/projects';
import Profile from './pages/profile/informations';
import VisualEditor from './pages/editeur/index'
import Utilisateur from './admin/utilisateur';
import TemplatesPage from './admin/templates-list';
import Accessoire  from './admin/accessoires'
import Tamplete from './admin/accessoires/tampletes/tamplete';
import DeleteTamplete from './admin/accessoires/delete';



function App() {
  return (

    <GoogleOAuthProvider clientId="418612961579-2bd2tub0o6q2di3uqe6cu1jqc7chjt61.apps.googleusercontent.com">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div>
            <BrowserRouter>
              <Routes>
                  <Route index element={<Home />} />
                  <Route path='/home' element={<Home />} />
                  <Route path='/login' element={<LoginPage />} />
                  <Route path='/register' element={<RegisterPage />} />
                  <Route path='/projects' element={<Projects />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/editeur/:id' element={<VisualEditor />} />
                  <Route path='/admin' element={<Page />} />
                  <Route path='/admin/applimobil' element={<Applimobil />} />
                  <Route path='/admin/appliweb' element={<Appliweb />} />
                  <Route path='/admin/sites' element={<Sites />} />
                  <Route path='/admin/utilisateur' element={<Utilisateur />} />
                  <Route path='/admin/accessoires' element={<Accessoire />} />
                  <Route path='/admin/statistiques' element={<Statistiques />} />
                  <Route path='/admin/statistiques/revenus' element={<Revenus/>} />
                  <Route path='/admin/templates' element={<TemplatesPage />} />
                  <Route path='/admin/accessoires/tampletes' element={<Tamplete/>} />
                  <Route path='/admin/accessoires/delete' element={<DeleteTamplete/>} />


                  {/* <Route path='/department-add/:facId' element={<DepartmentAdd />} /> */}


                  {/* <Route path='*' element={<NoPage />} /> */}
              </Routes>
            </BrowserRouter>
        </div>
            </PersistGate>
        </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
