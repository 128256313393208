'use client';

import './delete.css';
import MainLayout from '../../../layouts/MainLayout';
import axios from '../../../utils/axiosConfig';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast'; // Importez le composant Toast pour afficher le message

const DeleteTamplete = () => {
    const token = useSelector((state) => state.auth.token);
    const [tampletes, setTampletes] = useState([]); // État pour stocker les utilisateurs

    const fetchAllTampletes = async () => {
        try {
            const response = await axios.get('/projects/tampletes', {
                headers: {
                    'Authorization': `Bearer ${token.access}`
                }
            });
            setTampletes(response.data); // On stocke les utilisateurs récupérés dans l'état
        } catch (error) {
            console.error("Erreur lors de la récupération des informations des utilisateurs", error);
            alert("Erreur : " + error.message);
        }
    };

    useEffect(() => {
        fetchAllTampletes();
    }, [token.access]); // Utiliser le token.access comme dépendance

    return (
        <MainLayout>
            <div className='page1'>
                <div className='contain1'>
                    <Toast /> {/* Ajouter Toast pour afficher des messages */}
                    <ProductTable users={tampletes} /> {/* Passer les utilisateurs en tant que props */}
                </div>
            </div>
        </MainLayout>
    );
}

const ProductTable = ({ users }) => { // Recevoir les utilisateurs en tant que prop
    const dt = useRef(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const token = useSelector((state) => state.auth.token);

    const header = (
        <div className="table-header">
            <InputText
                type="search"
                onInput={(e) => setGlobalFilter(e.target.value)}
                placeholder="Rechercher..."
            />
        </div>
    );

    const handleDeleteClick = async (user) => {
        axios.delete(`/projects/tampletes/${user.id}/delete`, {
            headers: { Authorization: `Bearer ${token.access}` }
          })
            .then(() => {
              alert('Tamplete supprimé avec succès');
            })
            .catch(error => {
              alert('Erreur lors de la suppression du tamplete '+error);
        });
    };

    const deleteBodyTemplate = (rowData) => {
        return (
            <button className="delete-button" style={{ maxWidth: '5rem'}} onClick={() => handleDeleteClick(rowData)}>
                <i className="fas fa-trash">Delete</i> {/* Icône de poubelle */}
            </button>
        );
    };

    return (
        <DataTable ref={dt} value={users} selection={selectedUsers} onSelectionChange={(e) => setSelectedUsers(e.value)}
            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affiche {first} à {last} sur {totalRecords} utilisateurs" globalFilter={globalFilter} header={header} >

            <Column field="name" header="Nom " sortable style={{ minWidth: '12rem' }}></Column>
            <Column field="created_by" header="Propriétaire" sortable style={{ minWidth: '16rem' }}></Column>
            <Column field="created_at" header="Date de création" sortable style={{ minWidth: '16rem' }}></Column>
            <Column field="description" header="Description" sortable style={{ minWidth: '16rem' }}></Column>
            <Column body={deleteBodyTemplate} header="Actions" exportable={false} style={{ maxWidth: '8rem' }}></Column>
        </DataTable>
    );
};

export default DeleteTamplete;